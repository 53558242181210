import { render, staticRenderFns } from "./MainPage.vue?vue&type=template&id=03d9b622&scoped=true"
import script from "./MainPage.vue?vue&type=script&lang=js"
export * from "./MainPage.vue?vue&type=script&lang=js"
import style0 from "./MainPage.vue?vue&type=style&index=0&id=03d9b622&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "03d9b622",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\2_devcode\\06_lincao\\gjlc-rbc-web\\develop\\@lcrbc\\lincao-apps-luotu\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('03d9b622')) {
      api.createRecord('03d9b622', component.options)
    } else {
      api.reload('03d9b622', component.options)
    }
    module.hot.accept("./MainPage.vue?vue&type=template&id=03d9b622&scoped=true", function () {
      api.rerender('03d9b622', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/MainPage.vue"
export default component.exports