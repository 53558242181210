<template>
  <div style=" overflow: hidden;">
    <div id="map" ref="map" style="width: 100%;position: relative; overflow: hidden;" @mousemove="getMousePosition">
      <Row type="flex" justify="space-between" class="sticky-div"
        style="background: #06834ce3;opacity: 1;;height: 48px; align-items: center;">
        <Col span="10" style="color: #F2F7F7; font-weight: 400; font-size: 14px;">
        <span style="margin-left: 17px;margin-right: 10px;">项目类别 <Select v-model="queryParam.xmlb" multiple
            placeholder="全部" size="small" s style="width:138px;margin-left: 10px;">
            <Option v-for="item in baseData.xmlbList" :value="item.value" :key="item.value">{{ item.label }}</Option>
          </Select></span>
        <span style="margin-left: 34px;margin-right: 10px;"> 地区 <Select v-model="queryParam.dq" multiple placeholder="全部"
            size="small" style="width:138px;margin-left: 10px;">
            <Option v-for="item in baseData.dqList" :value="item.value" :key="item.value">{{ item.label }}</Option>
          </Select></span>
        <span style="margin-left: 34px;"></span>年份<Select v-model="queryParam.nf" multiple placeholder="全部" size="small"
          style="width:138px;margin-left: 10px;">
          <Option v-for="item in baseData.nfList" :value="item.value" :key="item.value">{{ item.label }}</Option>
        </Select></span>
        </Col>
        <Col span="10" offset="4" style=" ">
        <div style=" text-align: right; ">
          <span class="title_right_btn">
            <img class="title_right_img" src="/output/@lcrbc/lincao-apps-luotu/dist/imgs/luotu-main/gktj.png">概况统计
          </span>
          <Divider type="vertical" />
          <span class="title_right_btn">
            <img class="title_right_img" src="/output/@lcrbc/lincao-apps-luotu/dist/imgs/luotu-main/tcsz.png">图层设置
          </span>
          <Divider type="vertical" />
          <span class="title_right_btn">
            <img class="title_right_img" src="/output/@lcrbc/lincao-apps-luotu/dist/imgs/luotu-main/tbtj.png">图斑统计
          </span>
          <Divider type="vertical" />
          <span class="title_right_btn">
            <img class="title_right_img" src="/output/@lcrbc/lincao-apps-luotu/dist/imgs/luotu-main/xmjs.png">项目检索
          </span>
          <Divider type="vertical" />
          <span class="title_right_btn" @click="initPage">
            <img class="title_right_img" src="/output/@lcrbc/lincao-apps-luotu/dist/imgs/luotu-main/cz.png">页面重置
          </span>
          <Divider type="vertical" />
          <span class="title_right_btn" style="margin-right: 20px;" @click="toggleFullScreen">
            <img class="title_right_img" src="/output/@lcrbc/lincao-apps-luotu/dist/imgs/luotu-main/qpzs.png">
            {{ configData.fullscreenTitle }}
          </span>
        </div>
        </Col>
      </Row>
    </div>
  </div>
</template>
<script>
import '../assets/openLayer/ol.css'


export default {
  name: 'MainPage',
  components: {

  },
  data () {
    return {
      olMap: null,
      rs: {
        matrixIds: [],
        resolutions: [],
      },
      queryParam: {
        xmlb: [],
        dq: [],
        nf: ['2024'],
      },
      baseData: {
        xmlbList: [
          {
            value: '00',
            label: '油茶',
          },
          {
            value: '01',
            label: '国土绿化',
          }
        ], dqList: [
          {
            value: '00',
            label: '天津',
          },
          {
            value: '01',
            label: '北京',
          }
        ], nfList: [
          {
            value: '2024',
            label: '2024',
          },
          {
            value: '2023',
            label: '2023',
          }
        ],
      },
      configData: {
        isFullscreen: false,
        fullscreenTitle: '全屏窗口'
      }
    }
  },
  methods: {
    initMap (callback) {
      const script = document.createElement('script')
      script.src = '/output/@gms/lincao-plugin-billexpand/dist/openLayerJs/ol.js' // ol.js文件的相对路径
      script.async = true
      script.type = 'text/javascript'
      script.onload = callback
      document.head.appendChild(script)
      // this.$refs.map.style.height = window.outerHeight - 190 + 'px'
    },
    isNotNull (val) {
      return val !== '' && val !== undefined && val !== null
    },
    createMap () {
      let calculateZoomAndCenter = null
      if (!this.isNotNull(calculateZoomAndCenter))
      {
        calculateZoomAndCenter = {
          zoom: 5,
          center: [116, 39],
        }
      }

      /* 显示鼠标所在位置坐标 */
      let mousePositionControl = new ol.control.MousePosition({
        coordinateFormat: ol.coordinate.createStringXY(4), //经纬度保留几位小数
        projection: 'EPSG:4326', //坐标系
        className: 'ol-mouse-position',
        undefinedHTML: '&nbsp;',
      })
      /* 自定义版权信息控件 */
      let copyRightDom = document.createElement('div')
      copyRightDom.innerHTML = '<a style="color:rgb(255,255,255)">©2024 天地图. All rights reserved.</a>'
      copyRightDom.style.position = 'absolute'
      copyRightDom.style.left = '30px'
      copyRightDom.style.bottom = '10px'
      let copyRight = new ol.control.Control({
        element: copyRightDom,
      })

      /* 自定义隐藏列表及选项控件 */
      let hiddenTableDom = document.createElement('div')
      let buttonDom = document.createElement('button')
      buttonDom.title = '隐藏表单及选项'
      buttonDom.id = 'hiddenTable'
      buttonDom.textContent = '数据'
      buttonDom.style.backgroundColor = 'rgba(0,60,136,.5)'
      buttonDom.style.color = 'rgba(255,255,255,1)'
      buttonDom.style.width = '40px'
      buttonDom.addEventListener('click', this.handleHiddenTable)
      hiddenTableDom.appendChild(buttonDom)
      hiddenTableDom.style.position = 'absolute'
      hiddenTableDom.style.backgroundColor = 'hsla(0,0%,100%,.4)'
      hiddenTableDom.style.borderRadius = '4px'
      hiddenTableDom.style.padding = '2px'
      hiddenTableDom.style.right = '3em'
      hiddenTableDom.style.top = '.5em'

      let hiddenTable = new ol.control.Control({
        element: hiddenTableDom,
      })

      //初始化地图
      this.olMap = new ol.Map({
        target: 'map',
        view: new ol.View({
          center: calculateZoomAndCenter.center,
          zoom: calculateZoomAndCenter.zoom,
          projection: 'EPSG:4326',
        }),
        logo: true,
        loadTilesWhileAnimating: true,
        controls: ol.control
          .defaults({
            //不显示初始控件
            attribution: false,
            zoom: false,
          })
          .extend([]),
      })
      this.generateMatrixIdsAndResolutions(18)
      this.olMap.addLayer(this.createLayer_wmts_c('http://t0.tianditu.gov.cn/img_c/wmts?tk=d2219198776657ebc5673d542b83bda2', 'img'))
      this.olMap.addLayer(this.createLayer_wmts_c('http://t0.tianditu.gov.cn/cia_c/wmts?tk=ed9f7ff525e559cbab3f070f5203c1b8', 'cia'))
      //增加全屏控件
      this.olMap.addControl(mousePositionControl)
      this.olMap.addControl(copyRight)

      // 监听地图的点击事件
      this.olMap.on('click', this.layerOnclickEventHandle)
      this.getAllLayers()
      //this.getLuotuInfoByPage(DataTypePlan)

      // this.tableWidth = this.olMap.getSize()[0] - 200
      //let map = this.selectParamObj.layerTableCheckBoxItem.map(item => item.key)
      // 添加鼠标移动事件监听器


      this.olMap.updateSize();
    },
    getAllLayers () {
      let _this = this
      //this.$Message.error('数据初始化getAllLayers')
    },
    getMousePosition (event) {
      const pixel = this.olMap.getEventPixel(event);
      const coordinate = this.olMap.getCoordinateFromPixel(pixel);
      console.log(coordinate[0] + "---" + coordinate[1]);

    },
    /**
     * 创建影像图图层
     */
    createLayer_wmts_c (layerUrl, layer) {
      return new ol.layer.Tile({
        source: new ol.source.WMTS({
          url: layerUrl,
          layer: layer,
          matrixSet: 'c',
          format: 'tiles',
          style: 'default',
          projection: 'EPSG:4326',
          tileGrid: new ol.tilegrid.WMTS({
            origin: [-180, 90],
            resolutions: this.rs.resolutions,
            matrixIds: this.rs.matrixIds,
          }),
          crossOrigin: 'anonymous',
        }),
      })
    },
    /**
     * 计算分辨率及天地图瓦片的矩阵id数组
     * @param maxZoom 最大放大倍数
     */
    generateMatrixIdsAndResolutions (maxZoom) {
      let matrixIds = []
      let resolutions = []
      let tileSize = 256
      for (let zoom = 0; zoom <= maxZoom; zoom++)
      {
        let matrixId = zoom.toString()
        let resolution = 360 / (Math.pow(2, zoom) * tileSize)
        matrixIds.push(matrixId)
        resolutions.push(resolution)
      }
      this.rs.matrixIds = matrixIds
      this.rs.resolutions = resolutions
    },
    toggleFullScreen () {
      const elem = this.$refs.map;
      if (!document.fullscreenElement)
      {
        this.configData.fullscreenTitle = '恢复窗口'
        elem.requestFullscreen().catch(err => {
          alert(`当前设备或浏览器不支持全屏模式`);
          this.configData.fullscreenTitle = '全屏窗口'
        });
      } else
      {
        document.exitFullscreen();
        this.configData.fullscreenTitle = '全屏窗口'
      }
    },
    setMapHeight () {
      this.$nextTick(() => {
        // 设置高度
        this.$refs.map.style.height = window.outerHeight - 10 + 'px'
        this.olMap.updateSize();
      });
    },
    initPage () {

    }
  },
  watch: {
    // 监听 userInfo.username 字段的变化
    'isFullscreen': function (newValue, oldValue) {
      if (newValue !== oldValue)
      {
        console.log(`Username changed from ${oldValue} to ${newValue}`);
      } else
      {

      }
    }
  },

  created () {
    this.initMap(this.createMap)
  },
  mounted () {
    this.setMapHeight()
  },
}
</script>

<style scoped>
#map {
  overflow: hidden;
}

.gtlvClass {
  /*position: absolute !important;*/
  top: 0;
  left: 0;
  z-index: 9999;
  pointer-events: none;
}

.sticky-div {
  position: absolute;
  width: 100%;
  top: 0px;
  /* 与顶部的距离 */
  left: 0px;
  /* 与左侧的距离 */
  z-index: 1000;
  /* 确保它在其他内容的上方 */
}

.title_right_btn {
  width: 56px;
  height: 30px;
  cursor: pointer;
  font-family: PingFangSC-Regular;
  font-weight: 400;
  font-size: 14px;
  color: #FFFFFF;
}

.title_right_img {
  display: inline-block;
  vertical-align: middle;
  width: 16px;
  height: 16px;
  margin-bottom: 5px;
  margin-right: 9px;
}

/deep/ .ivu-divider {
  background: #ffffff26;
}

/deep/ .ivu-select-selection {
  height: 32px !important;
  padding-top: 5px;
  background-color: #0A7B47;
  border: 1px solid #ffffff33;
  border-radius: 0 4px 4px 0;
}

/deep/ .ivu-select-multiple .ivu-tag {
  border: 0px solid #ffffff33;
  color: #ffffff;
  background-color: #0A7B47;
}

/deep/ .ivu-select-multiple .ivu-select-selection div {
  overflow: hidden;
  white-space: nowrap;
}

/deep/ .ivu-select-multiple .ivu-select-selection .ivu-select-placeholder {
  color: #ffffff;
}

/deep/ .ivu-icon-ios-close:before {
  color: #ffffff;
}

/deep/ .ivu-tag-text {
  font-family: PingFangSC-Regular;
  font-weight: 400;
  font-size: 14px;
  color: #FFFFFF;
}

/*  修改select组件的字体颜色*/
/deep/ .ivu-select-selected-value {
  color: #ffffff;
}

/deep/ .ivu-select-arrow {
  color: #ffffff;
}
</style>
